import React from 'react';

const TITLE_COLOR = '#000000'; // Color for titles and key text

// CholaMenu Component
const CholaMenu = () => (
  <div style={{ fontFamily: 'Arial, sans-serif', margin: '20px', lineHeight: '1.6' }}>
    <h1 style={{ textAlign: 'center', color: TITLE_COLOR }}>CHOLA CAFE MENU</h1>

    {/* Soups Section */}
    <Section title="SOUPS">
      <MenuItem name="TOMATO SOUP" price="$5" description="Aromatic spices infused in a rich tomato base." />
      <MenuItem name="MUTTON BONE SOUP" price="$5" description="Tender mutton bones slow-cooked in spices." />
      <MenuItem name="CHICKEN PEPPER SOUP" price="$5" description="Tender chicken with a hint of pepper." />
      <MenuItem name="MUSHROOM SOUP" price="$5" description="Mushrooms infused with Indian spices." />
    </Section>

    {/* Vegetarian Starters Section */}
    <Section title="STARTERS (VEGETARIAN)">
          <MenuItem name="SAMOSA (2 pcs)" price="$7" description="Crispy pastry filled with spiced potatoes and peas." />
          <MenuItem name="MYSORE BONDA (5 pcs)" price="$7" description="Deep-fried fritters made from lentil and rice batter." />
          <MenuItem name="ASSORTED BAJJI (6 pcs)" price="$7" description="Mixed veggie fritters in spiced chickpea batter." />
          <MenuItem name="MEDU VADA (2 pcs)" price="$7" description="Crispy lentil fritter with cumin, pepper, and herbs." />
          <MenuItem name="ONION PAKODA" price="$7" description="Thinly sliced onions mixed with gram flour, deep-fried." />
          <MenuItem name="ALOO BONDA (5 pcs)" price="$7" description="Spiced potato filling coated in chickpea batter." />
          <MenuItem name="SAMBAR VADA" price="$8" description="Medu vada soaked in tangy sambar with chutney." />
          <MenuItem name="GOBI 65" price="$13" description="Spicy, crispy fried cauliflower marinated in spices." />
          <MenuItem name="PANEER 65" price="$13" description="Crispy paneer cubes marinated in spices and yogurt." />
          <MenuItem name="CHILLI PANEER" price="$13" description="Paneer sautéed with bell peppers and sauces." />
          <MenuItem name="GOBI MANCHURIAN / CHILLI GOBI" price="$13" description="Crispy cauliflower in chili-garlic sauce." />
          <MenuItem name="MUSHROOM 65" price="$13" description="Button mushrooms in a spicy, crispy coating." />
          <MenuItem name="CHILLI MUSHROOM" price="$13" description="Mushrooms sautéed with bell peppers and spices." />
        </Section>

    {/* Non-Vegetarian Starters Section */}
    <Section title="STARTERS (NON-VEGETARIAN)">
          <MenuItem name="CHICKEN 65" price="$13" description="Marinated boneless chicken with spices and yogurt." />
          <MenuItem name="CHICKEN LOLLIPOP" price="$13" description="Chicken drumettes deep-fried until golden." />
          <MenuItem name="CHICKEN MASALA LOLLIPOP" price="$14" description="Drumettes with a spicy masala coating." />
          <MenuItem name="CHOLA’S CHICKEN SUKKA" price="$14" description="Dry chicken sautéed with spices, coconut, and onions." />
          <MenuItem name="CHICKEN 555" price="$14" description="Crispy fried chicken with a tangy glaze." />
          <MenuItem name="CHICKEN MANCHURIAN" price="$14" description="Chicken balls in a tangy sauce with peppers." />
          <MenuItem name="CHENNAI CHILLI CHICKEN" price="$14" description="Chicken stir-fried with peppers and tangy sauce." />
          <MenuItem name="ANDRA CHILLI CHICKEN" price="$14" description="Spicy, vibrant red chicken dish." />
          <MenuItem name="KOZHI (CHICKEN) VEPUDU" price="$14" description="Pan-fried Andhra-style chicken with spices." />
          <MenuItem name="PEPPER CHICKEN SUKKA" price="$14" description="Dry roasted chicken with bold pepper flavors." />
          <MenuItem name="MUTTON SUKKA" price="$17" description="Mutton fried with onions and aromatic spices." />
          <MenuItem name="CHOLA’S SPECIAL MUTTON VARUVAL" price="$17" description="Pan-fried mutton with a flavorful spice blend." />
          <MenuItem name="PRAWN SUKKA" price="$16" description="Prawns with tamarind, coconut, and spices." />
          <MenuItem name="FISH APOLLO" price="$14" description="Crispy fish bites with a tangy-spicy sauce." />
          <MenuItem name="FISH TAWA ROAST" price="$14" description="Fish fillets pan-roasted with spices and onions." />
          <MenuItem name="FISH 65" price="$14" description="Bite-sized fish fried with aromatic spices." />
          <MenuItem name="OMLETTE" price="$5" description="Eggs with onions, tomatoes, and green chilies." />
          <MenuItem name="EGG PODIMAS" price="$5" description="Scrambled eggs with onions, tomatoes, and spices." />
          <MenuItem name="EGG PEPPER ROAST" price="$8" description="Boiled eggs cooked with a peppery masala." />
        </Section>

    {/* From the Clay Oven Section */}
    <Section title="FROM THE CLAY OVEN">
        <MenuItem name="CHICKEN TANDOORI" price="$15" description="Succulent chicken marinated in yogurt and aromatic spices, then cooked in a clay tandoor oven." />
        <MenuItem name="CHICKEN TIKKA KEBAB" price="$15" description="Bite-sized chicken pieces marinated in spices, skewered, and grilled to perfection." />
        <MenuItem name="FISH TIKKA" price="$16" description="Fresh fish chunks marinated in a zesty mixture of spices and herbs, then skewered and grilled." />
        <MenuItem name="SHRIMP TIKKA" price="$18" description="Plump shrimp marinated in rich Indian spices, skewered, and grilled to perfection." />
        <MenuItem name="VEG PANEER TIKKA" price="$15" description="Cubes of soft paneer marinated in a zesty mixture of spices, skewered, and grilled for a delightful vegetarian option." />
    </Section>

    {/* Rice and Noodles Section */}
    <Section title="Rice / Noodles">
        <MenuItem name="Vegetarian Fried Rice / Noodles" price="$13" description="Stir-fried rice or noodles with vegetables, soy sauce, and seasonings." />
        <MenuItem name="EGG FRIED RICE / NOODLES" price="$13" description="Fluffy rice or noodles stir-fried with scrambled eggs, vegetables, and savory soy sauce." />
        <MenuItem name="CHICKEN FRIED RICE / NOODLES" price="$14" description="Wok-tossed rice or noodles with tender chicken, assorted vegetables, and savory soy sauce." />
        <MenuItem name="SHRIMP FRIED RICE / NOODLES" price="$15" description="Plump shrimp and seasoned vegetables wok-tossed with fluffy rice or noodles and soy sauce." />
    </Section>
    {/* Vegetarians Main Course Section */}

    <Title title="VEGETARIANS MAIN COURSE"></Title>
    <Section title="( SERVED WITH RICE )">


    {/* <h2>SERVED WITH RICE</h2> */}
    <MenuItem name="MALAI KOFTA" price="$13" description="Delectable vegetarian dish featuring creamy milk and fried potato served in a rich tomato-based gravy, bursting with flavors of aromatic spices." />
    <MenuItem name="CHETTINAD MUSHROOM/EGG PLANT MASALA" price="$13" description="A spicy and flavorful South Indian dish featuring tender eggplant cooked with a rich blend of roasted spices, coconut, and tamarind for a tangy and aromatic delight." />
    <MenuItem name="MIXED VEG KURMA" price="$13" description="A rich South Indian curry featuring an assortment of vegetables cooked in a creamy coconut, onion, and tomato-based sauce infused with fragrant spices." />
    <MenuItem name="SAMBAR" price="$12" description="A flavorful South Indian lentil stew, prepared with Toor dal, vegetables, tamarind, and a blend of spices, offering a savory and tangy taste." />
    <MenuItem name="DAL FRY" price="$13" description="A flavorful Indian lentil dish made by tempering cooked lentils with aromatic spices, onions, tomatoes, and herbs." />
    <MenuItem name="DAL PALAK" price="$13" description="A nutritious Indian dish combining lentils and spinach, cooked to perfection with aromatic spices for a flavorful and wholesome meal." />
    <MenuItem name="KAJU PANEER" price="$13" description="A rich and creamy Indian dish featuring paneer and cashews cooked in a luscious tomato-based gravy, offering a perfect balance of flavors and textures." />
    <MenuItem name="KADAI PANEER" price="$13" description="Cubes of paneer cooked with colorful bell peppers, onions, and aromatic spices in a rich tomato-based gravy, creating a flavorful and hearty Indian dish." />
    <MenuItem name="PANEER TIKKA MASALA" price="$13" description="Cubes of marinated paneer cheese grilled to perfection, served in a rich and creamy tomato-based curry infused with spices." />
    <MenuItem name="PANEER BUTTER MASALA" price="$13" description="Creamy and rich tomato-based curry featuring soft paneer cheese, simmered in a luscious blend of buttery sauce and aromatic spices." />
    <MenuItem name="SAAG PANEER" price="$13" description="A flavorful Indian dish featuring cubes of paneer cheese simmered in a vibrant spinach and fenugreek sauce, enriched with spices and cream." />
    <MenuItem name="CHANNA MASALA/SAAG CHANNA" price="$13" description="A flavorful North Indian dish featuring tender chickpeas simmered in a rich onion and tomato-based sauce infused with a harmonious blend of spices." />
    </Section>

    {/* Chicken Main Course Section */}
    <Title title="CHICKEN MAIN COURSE"></Title>
    <Section title="( SERVED WITH RICE )">
  <MenuItem name="CHICKEN DHABA CURRY" price="$14" description="Tender chicken pieces cooked in a rich and flavorful tomato-based gravy, infused with a medley of traditional spices, onions, and aromatic herbs, reminiscent of the vibrant roadside Dhaba eateries." />
  <MenuItem name="ANDHRA CHICKEN CURRY" price="$14" description="A fiery South Indian dish featuring tender chicken pieces cooked in a rich, spicy gravy made with a blend of red chilies and traditional Andhra spices." />
  <MenuItem name="CHICKEN CHETTINAD" price="$14" description="A rich South Indian dish featuring tender chicken simmered in a bold and spicy gravy infused with roasted spices, coconut, and curry leaves." />
  <MenuItem name="CHENNAI CHICKEN CURRY" price="$14" description="A rich and spicy Chennai-style dish featuring tender chicken cooked in a flavorful blend of coconut milk, tomatoes, and traditional spices, offering a balance of heat and depth of flavors." />
  <MenuItem name="CHICKEN KURMA" price="$14" description="South Indian style gravy cooked with tender chicken, aromatic spices, and coconut paste." />
  <MenuItem name="CHOLA'S SPECIAL CHICKEN VARUTHA CURRY" price="$14" description="Tender pieces of chicken slow-cooked in a rich blend of Chola’s special roasted spices, coconut, and curry leaves, creating a flavorful and aromatic South Indian curry." />
  <MenuItem name="CHICKEN TIKKA MASALA" price="$14" description="Grilled chicken pieces simmered in a creamy tomato-based sauce, richly spiced with a harmonious blend of Indian flavors." />
  <MenuItem name="CHICKEN BUTTER MASALA" price="$14" description="Tender chicken cooked in a rich and creamy tomato-based gravy, infused with aromatic spices, and finished with a luscious touch of butter, creating a delectable Indian comfort dish." />
  <MenuItem name="KADAI CHICKEN" price="$14" description="A flavorful Indian dish where tender chicken pieces are cooked with assorted bell peppers, onions, and aromatic spices in a 'kadai' (wok), creating a delectable blend of textures and tastes." />
  <MenuItem name="SAAG CHICKEN" price="$14" description="A delightful Indian dish featuring tender pieces of chicken simmered in a vibrant and flavorful spinach-based gravy." />
  <MenuItem name="CHICKEN VINDALOO" price="$14" description="A fiery chicken dish made with onion, tomatoes, potato cubes, and fresh ground spices in Indo-Portuguese style." />
  <MenuItem name="KOZHI MILAGU (PEPPER CHICKEN) MASALA" price="$14" description="Chicken cooked with onion, tomato, and finished with fresh ground pepper." />
</Section>

{/* Mutton Main Course Section */}
<Section title="MUTTON MAIN COURSE">
  <MenuItem name="CHOLA’S SPECIAL MUTTON Masala" price="$17" description="A tantalizing blend of tender mutton pieces cooked in a rich, aromatic gravy infused with Chola Cafe's unique spice mix, creating a flavorful and indulgent dish." />
  <MenuItem name="MUTTON ROGAN JOSH" price="$17" description="A delectable Kashmiri curry featuring succulent mutton slow-cooked with a harmonious blend of aromatic spices, yogurt, and tomatoes, resulting in a rich and deeply flavorful dish." />
  <MenuItem name="CHENNAI MUTTON CURRY" price="$17" description="A South Indian delight where tender mutton is simmered in a luscious gravy crafted with traditional spices, coconut milk, and curry leaves, delivering a burst of authentic Chennai flavors." />
  <MenuItem name="MUTTON CHETTINAD" price="$17" description="A spicy and aromatic South Indian dish featuring tender mutton cooked in a bold Chettinad masala, blending roasted spices, coconut, and curry leaves for an unforgettable flavor experience." />
  <MenuItem name="MUTTON KURMA" price="$17" description="A luxurious Indian dish where tender mutton is simmered in a velvety, aromatic gravy enriched with fragrant spices, creating a harmonious balance of flavors." />
  <MenuItem name="MUTTON VINDOLOO" price="$17" description="A fiery mutton dish made with onions, tomatoes, potato cubes, and fresh ground spices in Indo-Portuguese style." />
</Section>

{/* Sea Food and Egg Section */}
<Section title="SEA FOOD AND EGG">
  <MenuItem name="PRAWN MASALA" price="$17" description="Succulent prawns cooked in a fragrant and spicy masala gravy, crafted with aromatic spices and tomatoes, delivering a delightful and flavorful seafood dish." />
  <MenuItem name="MADRAS FISH CURRY" price="$15" description="A zesty South Indian dish featuring tender fish pieces simmered in a tangy and aromatic gravy infused with tamarind, coconut, and a blend of traditional spices, capturing the authentic flavors of Madras (Chennai) cuisine." />
  <MenuItem name="CHENNAI EGG CURRY" price="$13" description="A delicious South Indian delicacy showcasing three perfectly boiled eggs immersed in a rich and spiced gravy, prepared with a medley of aromatic ingredients, tomatoes, and coconut milk, reflecting the unique essence of Chennai's culinary heritage." />
  <MenuItem name="SHRIMP VINDALOO" price="$15" description="A fiery shrimp dish made with onions, tomatoes, potato cubes, and fresh ground spices in Indo-Portuguese style." />
</Section>

{/* Rice Corner Section */}
<Title title="RICE CORNER"></Title>
<Section title="BIRYANI (VEG)">
  <h2></h2>
  <MenuItem name="VEGETABLE DUM BIRYANI" price="$13" description="A fragrant and flavorful Indian rice dish cooked in layers, featuring a blend of aromatic basmati rice, assorted vegetables, and a medley of spices, slow-cooked to perfection for a harmonious and delightful vegetarian feast." />
  <MenuItem name="PANEER BIRYANI" price="$14" description="A delectable Indian rice dish where succulent cubes of paneer (Indian cottage cheese) are layered with fragrant basmati rice, aromatic spices, and herbs, resulting in a flavorful and satisfying vegetarian biryani." />
</Section>

<Section title="BIRYANI (NON-VEG)">
  <MenuItem name="NAWABI CHICKEN BIRYANI" price="$15" description="A regal and aromatic Indian rice delicacy, featuring tender chicken marinated in rich spices, layered with fragrant basmati rice, and slow-cooked to perfection in the traditional ‘Dum’ style, capturing the opulent flavors of Nawabi cuisine." />
  <MenuItem name="SUPREME BONELESS CHICKEN BIRYANI" price="$15" description="An exquisite and indulgent Indian rice dish, showcasing succulent pieces of boneless chicken marinated with a blend of spices, layered with fragrant rice, and cooked to perfection, resulting in a luxurious and flavorful biryani experience." />
  <MenuItem name="NELLORE BONELESS CHICKEN BIRYANI" price="$16" description="A flavorful and aromatic South Indian rice dish featuring tender boneless chicken, marinated with traditional spices, and layered with fragrant rice capturing the distinctive taste of Nellore's culinary heritage." />
  <MenuItem name="DINDUGAL THALAPAKATTU MUTTON DUM BIRYANI" price="$16" description="A renowned South Indian specialty where succulent mutton is marinated in authentic spices, layered with fragrant seeragasamba rice, and slow-cooked to perfection, embodying the rich and robust flavors of Dindugal's famous Thalapakattu style." />
  <MenuItem name="FISH BIRYANI" price="$17" description="A delectable Indian rice dish featuring marinated fish pieces layered with aromatic basmati rice, fragrant spices, and herbs, creating a delightful fusion of seafood and biryani flavors." />
  <MenuItem name="SHRIMP BIRYANI" price="$17" description="A flavorful Indian rice dish showcasing succulent shrimp marinated with aromatic spices, layered with fragrant basmati rice, and slow-cooked to perfection, resulting in a delightful fusion of seafood and biryani goodness." />
  <MenuItem name="EGG BIRYANI" price="$14" description="A delightful Indian rice dish featuring three boiled eggs infused with aromatic spices, layered with fragrant basmati rice, and cooked to perfection, offering a unique and satisfying twist on traditional biryani flavors." />
</Section>


<Title title="VARIETY RICE"></Title>
<Section title="( SERVED WITH PICKLE, VADA AND PAPAD )">
  <MenuItem name="CURD RICE" price="$11" description="A soothing South Indian comfort food made from cooked rice mixed with yogurt, seasoned with spices, and often tempered with mustard seeds, curry leaves, and garnished with coriander, offering a cooling and refreshing dish." />
  <MenuItem name="SAMBAR RICE" price="$12" description="A wholesome South Indian dish where cooked rice is served with sambar, a flavorful lentil-based vegetable stew enriched with spices and vegetables, creating a balanced and satisfying meal." />
</Section>

<Title title="DOSA CORNER"></Title>
<Section title="VEG - DOSA  (ADD TOPPINGS FOR $1 EACH – TOMATO, PODI, CHEESE, ONION, POTATO MASALA, GHEE)">
  
  <MenuItem name="PLAIN DOSA" price="$11" description="Thin, crispy South Indian crepe made from fermented rice batter." />
  <MenuItem name="MASALA DOSA" price="$12" description="Crunchy dosa filled with spiced potato, a South Indian culinary delight." />
  <MenuItem name="MYSORE DOSA" price="$12" description="Crispy South Indian crepe smeared with spicy Mysorian red chutney, flavorsome." />
  <MenuItem name="GHEE DOSA" price="$12" description="A golden and crisp South Indian crepe made with fermented batter, cooked in clarified butter (ghee), offering a rich and indulgent flavor." />
  <MenuItem name="RAVA DOSA" price="$12" description="A lacy and crispy South Indian crepe made with semolina (rava), rice flour, and spices, known for its unique texture and quick preparation." />
  <MenuItem name="MALLI DOSA" price="$12" description="A flavorful South Indian crepe topped with fragrant coriander (malli) chutney, enhancing the taste of the crispy dosa." />
  <MenuItem name="KAL DOSA" price="$12" description="Fluffy, fermented South Indian crepe, smaller and thicker, often served in sets with flavorful chutneys." />
</Section>

<Title title="NON-VEG DOSA"></Title>
<Section title="( SERVED WITH CHICKEN CURRY OR SAMBAR AND CHUTNEY )">
  
  <MenuItem name="EGG DOSA" price="$12" description="Egg-filled South Indian crepe, a delicious fusion of dosa and scrambled eggs." />
  <MenuItem name="CHICKEN DOSA" price="$13" description="Savory crepe filled with spiced chicken, a delightful fusion of South Indian dosa and flavorful chicken filling." />
  <MenuItem name="MUTTON DOSA" price="$14" description="Savory dosa filled with seasoned mutton, creating a delicious blend of South Indian and meaty flavors." />
  <MenuItem name="PLAIN UTHAPPAM" price="$11" description="Thick, fluffy South Indian pancake made from fermented lentil and rice batter, often topped with vegetables." />
  <MenuItem name="ONION UTHAPPAM" price="$12" description="Thick South Indian pancake topped with onions, cooked until golden-brown, offering a flavorful and satisfying meal." />
  <MenuItem name="VEG UTHAPPAM" price="$12" description="Thick South Indian pancake topped with vegetables, a flavorful and satisfying dish." />
</Section>

<Section title="SOUTH INDIAN DELICACIES">
  <MenuItem name="IDLY (2 PIECES)" price="$7" description="Steamed rice and lentil cake, a staple South Indian breakfast, served with chutney and sambar." />
  <MenuItem name="SAMBAR IDLY" price="$9" description="Steamed rice cakes soaked in lentil-based vegetable stew, a classic South Indian breakfast dish." />
  <MenuItem name="MINI GHEE IDLY" price="$9" description="Small, spongy steamed rice cakes, enriched with ghee, a delightful South Indian bite-sized delicacy." />
  <MenuItem name="CHENNAI PODI IDLY" price="$9" description="Traditional South Indian dish where soft idlis are coated with flavorful spice powder made from lentils and served with chutney." />
  <MenuItem name="GHEE PONGAL" price="$11" description="Savory South Indian rice dish cooked with ghee, lentils, and spices, offering rich and comforting flavors." />
  <MenuItem name="IDLY VADA COMBO" price="$10" description="Popular South Indian breakfast featuring soft rice cakes (idlis) and crispy lentil fritters (vadas)." />
  <MenuItem name="PONGAL VADA COMBO" price="$11" description="A delightful South Indian breakfast pairing creamy rice and lentil dish (pongal) with crispy lentil fritters (vadas)." />
</Section>

<Section title="Chola’s Special Bread Corner">
  <MenuItem name="CHAPATI WITH VEG OR CHICKEN" price="$11" description="A versatile Indian wheat bread, complements well with a choice of flavorful vegetable or tender chicken curry, creating a satisfying meal." />
  <MenuItem name="POORI WITH POTATO MASALA OR CHANNA" price="$11" description="Deep-fried bread (poori) served with spiced potato masala or chickpea curry, a flavorful Indian dish." />
  <MenuItem name="CHOLA PURI (BHATURA)" price="$12" description="Deep-fried bread (bhatura) served with spicy chickpea curry (chola), a popular North Indian dish." />
  <MenuItem name="PAROTTA - WITH VEG or CHICKEN KURMA" price="$12" description="Flaky, layered South Indian flatbread served with choice of aromatic vegetable or tender chicken curry." />
  <MenuItem name="VEECHU PAROTTA - WITH VEG OR CHICKEN KURMA" price="$12" description="Flaky, layered soft and fluffy flatbread served with choice of aromatic vegetable or tender chicken curry." />
  <MenuItem name="EGG VEECHI PAROTTA - WITH VEG OR CHICKEN KURMA" price="$12" description="Flaky, layered soft and fluffy flatbread served with choice of aromatic vegetable or tender chicken curry." />
  <MenuItem name="KOTHU PAROTTA ( Veg $12 | Egg $13 | Chicken $13 | Mutton $14 )" price="" description="Shredded parotta, mixed with eggs or vegetables or chicken or mutton, spices aromatic dish, popular in South India." />
  <MenuItem name="CHILLI PAROTTA (EVG)" price="$13" description="Shredded parotta, tossed with spicy sauce, onions, bell peppers, Indo-Chinese fusion, flavorful street food." />
  <MenuItem name="TANDOORI ROTI" price="$4" description="Indian flatbread, cooked in a tandoor/clay oven, with a slightly smoky flavor and texture." />
  <MenuItem name="METHI NAAN" price="$4" description="Flavored Indian bread made with fenugreek leaves, often cooked in a tandoor for a unique taste." />
  <MenuItem name="MINT NAAN" price="$4" description="Indian flatbread infused with fresh mint, offering a delightful aromatic twist, often cooked in a tandoor." />
  <MenuItem name="GREEN CHILLI NAAN" price="$4" description="Indian flatbread infused with chopped green chilies, adding a spicy kick, often cooked in a tandoor." />
  <MenuItem name="BUTTER NAAN" price="$3" description="Soft Indian bread brushed with butter, a popular accompaniment to curries and dishes." />
  <MenuItem name="GARLIC NAAN" price="$4" description="Flavored Indian bread topped with minced garlic, often cooked in a tandoor." />
  <MenuItem name="BHATURA" price="$5" description="Deep-fried Indian bread made from fermented dough, puffy and soft." />
  <MenuItem name="POORI (1 pc)" price="$4" description="" />
  <MenuItem name="PAROTTA (1 pc)" price="$4" description="" />
</Section>

    {/* Desserts Section */}
    <Section title="DESSERTS">
      <MenuItem name="GULAB JAMUN" price="$5" description="Milk-based fried balls soaked in syrup." />
      <MenuItem name="RASAMALAI" price="$5" description="Cheese patties in saffron milk." />
      <MenuItem name="CARROT HALWA" price="$5" description="Grated carrots cooked with milk and sugar." />
    </Section>

    {/* Drinks Section */}
    <Section title="Drinks">
  <MenuItem name="SPECIAL MADRAS COFFEE" price="$3" description="Strong, aromatic South Indian coffee made from dark roasted coffee beans and chicory." />
  <MenuItem name="SPECIAL TEA" price="$3" description="A spiced and aromatic Indian tea made by brewing black tea with a blend of spices like cinnamon, cardamom, cloves, and ginger, often infused with milk and sweetened with sugar for a flavorful and comforting beverage." />
  <MenuItem name="MANGO LASSI" price="$4" description="Creamy and refreshing drink made with yogurt and ripe mangoes." />
  <MenuItem name="FOUNTAIN SODA" price="$2" description="Coke, Sprite, Diet Coke, Canada Dry, Dr. Pepper." />
  <MenuItem name="BOTTLED WATER" price="$1" description="" />
</Section>
<Section> </Section>
<Section title="Note : Menu some items price may have changed"></Section>


  </div>
);

// Section Component
 
const Section = ({ title, children }) => (
  <div style={{ margin: '30px 0' }}>
    <h2 style={{ textAlign: 'center', color: TITLE_COLOR }}>{title}</h2>
    <hr
      style={{
        border: 'none',
        height: '2px',
        backgroundColor: TITLE_COLOR,
        marginTop: '20px',
      }}
    />
    {children}
  </div>
);

const Title = ({ title, children }) => (
    <div style={{ margin: '30px 0' }}>
      <h2 style={{ textAlign: 'center', color: TITLE_COLOR }}>{title}</h2>
      {children}
    </div>
  );

// MenuItem Component
const MenuItem = ({ name, price, description }) => (
  <div style={{ margin: '15px 0' }}>
    <span
      style={{
        fontWeight: 'bold',
        fontSize: '1.1em',
        marginLeft: '0px',
        color: TITLE_COLOR,
      }}
    >
      {name}
    </span>
    <span
      style={{
        float: 'right',
        fontWeight: 'bold',
        marginRight: '0px',
        color: TITLE_COLOR,
      }}
    >
      {price}
    </span>
    <p style={{ marginLeft: '', marginRight: '100px', color: TITLE_COLOR }}>{description}</p>
  </div>
);

export default CholaMenu;
