import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './Home.js';
import Privacy from './components/Privacy.js';
import GoToAppStore from './components/GotoAppStore.js';
import Faq from './components/Faq.js';
import Feedback from './components/Feedback.js';
import CholaMenu from './components/menu.js';


const App = () => (
  <Router>
    <Routes>
      {/* Route for Home page */}
      <Route path="/" element={<Home/>} />
      {/* Route for Privacy Policy page */}
      <Route path="/privacy" element={<Privacy/>} />
      {/* Route for QR App Store page */}
      <Route path="/GoToAppStore" element={<GoToAppStore/>} />
      {/* Route for Products menu */}
      <Route path="/menu" element={<CholaMenu/>} />
      {/* FAQ */}
      <Route path="/faq" element={<Faq />} />
      {/* Feedback */}
      <Route
        path="/feedback/:userId?"
        element={
          <div style={{ padding: "10px" }}>
            <Feedback />
          </div>
        }
      />
          </Routes>
  </Router>
);

export default App;
